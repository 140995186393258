<template>
    <div class="report-content">
        <div class="ctop flex-align">
            <div class="exercise" :class="{'active':type=='exercise'}" @click="toggle('exercise')">模拟练习情况</div>
            <!-- <div class="game" :class="{'active':type=='game'}" @click="toggle('game')">正式比赛情况</div> -->
        </div>
        <div class="gailan">
            <div class="gltitle flex-align-around">
                概览
            </div>
            <div class="moniinfo flex-align" v-if="type=='exercise'">
                <div class="score">
                    <div class="score_top flex-align-around">
                        <div class="flex-align">
                            <img src="@/assets/images/active/score.png" />
                            <span>最高正确率</span>
                        </div>
                    </div>
                    <div class="score_bottom">{{reportInfo.highest?reportInfo.highest:0}}<span>%</span></div>
                </div>
                <div class="line"></div>
                <div class="count">
                    <div class="count_top flex-align-around">
                        <div class="flex-align">
                            <img src="@/assets/images/active/pen.png" />
                            <span>模拟总次数</span>
                        </div>
                    </div>
                    <div class="count_bottom">{{reportInfo.totalCount}}<span>次</span></div>
                </div>
            </div>
            <div class="moniinfo flex-align" v-else>
                <div class="score">
                    <div class="score_top flex-align-around">
                        <div class="flex-align">
                            <img src="@/assets/images/active/score.png" />
                            <span>比赛用时</span>
                        </div>
                    </div>
                    <!-- dayjs(item.createTime).format('YYYY-MM-DD HH:mm') -->
                    <div class="score_bottom">{{timeFormat(reportInfo.answerLength*1000)}}</div>
                </div>
                <div class="line"></div>
                <div class="count">
                    <div class="count_top flex-align-around">
                        <div class="flex-align">
                            <img src="@/assets/images/active/pen.png" />
                            <span>客观题正确率</span>
                        </div>
                    </div>
                    <div class="count_bottom">{{reportInfo.objectiveRightRate}}<span>%</span></div>
                </div>
            </div>
        </div>
        <div class="study" v-show="type=='exercise'">
            <div class="stutitle">
                <span>模拟学情分析</span>
                <span style="display:none">时间：截止到2022年2月8日00:00</span>
            </div>
            <div class="stuinfo">
                <div class="infoitem flex-column-center">
                    <span class="infotitle">练习总次数</span>
                    <span class="infocount">{{reportInfo.totalCount}}<span>次</span></span>
                </div>
                <div class="line"></div>
                <div class="infoitem flex-column-center">
                    <span class="infotitle">每天平均练习次数</span>
                    <span class="infocount">{{reportInfo.avgEventdayCount}}<span>次</span></span>
                </div>
                <div class="line"></div>
                <div class="infoitem flex-column-center">
                    <span class="infotitle">练习平均正确率</span>
                    <span class="infocount">{{reportInfo.avgRage}}<span>%</span></span>
                </div>
            </div>
            <div class="chartwrap">
                <div class="myCharts" id="lineCharts"></div>
                <div class="legend">
                    <div class="legenditem">
                        <div class="item1"></div>
                        <span>班级平均正确率</span>
                    </div>
                    <div class="legenditem">
                        <div class="item2"></div>
                        <span>平均正确率</span>
                    </div>
                    <div class="legenditem">
                        <div class="item3"></div>
                        <span>个人平均正确率</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="power">
            <div class="powertitle">
                <span>阅读能力分析</span>
            </div>
            <div class="flex-align">
                <div class="left" id="radarChart"></div>
                <div class="line"></div>
                <div class="right">
                    <span>在模拟练习中</span>
                    <div class="info" v-if="power.length>0">
                        <span v-for="(item,index) in power" :key='index'>该同学在<span
                                class="">{{item.abilityName}}</span>方面的阅读能力最为突出，<span>{{item.level}}</span>年级平均水平;</span>
                        <div v-if="power.length>0">可加强关注本学生的批判思维和直接推论方面阅读能力的培训哦~</div>
                    </div>
                    <div class="info" v-else>
                        <span>该同学在<span class="">提取信息</span>方面的阅读能力最为突出，<span>--</span>年级平均水平;</span>
                        <span>该同学在<span class="">形成解释</span>方面的阅读能力最为突出，<span>--</span>年级平均水平;</span>
                        <span>该同学在<span class="">整体感知</span>方面的阅读能力最为突出，<span>--</span>年级平均水平;</span>
                        <span>该同学在<span class="">做出评价</span>方面的阅读能力最为突出，<span>--</span>年级平均水平;</span>
                        <span>该同学在<span class="">完成特定任务</span>方面的阅读能力最为突出，<span>--</span>年级平均水平;</span>
                        <!-- <div>可加强关注本学生的批判思维和直接推论方面阅读能力的培训哦~</div> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    export default {
        data() {
            return {
                type: 'exercise',
                reportInfo: {
                    // answerLength:0
                },
                power: [],
                option: {
                    color: ['#7832DA', '#EB4133', '#F8BD09'],
                    dataZoom: [{
                        type: 'inside',
                        start: 0,
                        end: 80,
                        zoomOnMouseWheel: false,
                        zoomLock: true,
                    }],
                    xAxis: {
                        type: 'category',
                        data: [],
                        splitLine: {
                            show: true
                        },
                        axisLine: {
                            lineStyle: {
                                width: 3,
                                color: '#D3D3D3'
                            }
                        },
                        axisLabel: {
                            color: '#666666',
                        },

                    },
                    yAxis: {
                        type: 'value',
                        min: 0,
                        max: 100,
                        splitNumber: 10,
                        axisLabel: {
                            formatter: '{value} %',
                            color: '#666666',
                        },
                        splitLine: {
                            show: true,
                            interval: 1,
                        },
                        axisLine: {
                            lineStyle: {
                                width: 3,
                                color: '#D3D3D3'
                            }
                        },
                        axisTick: {
                            show: false
                        }
                    },
                    series: [{
                            data: [0],
                            type: 'line',
                            // name:'班级平均成绩'
                        },
                        {
                            data: [0],
                            type: 'line',
                            // name:'平均成绩'
                        }, {
                            data: [0],
                            type: 'line',
                            // name:'个人平均成绩'
                        }
                    ]
                },
                radarOption: {
                    color: ['#FE8152', '#03ACF3'],
                    legend: {
                        data: ['学生姓名', '年级平均'],
                        orient: 'vertical',
                        itemWidth: 15,
                        itemHeight: 15,
                        right: 10,
                        top: 50,
                    },
                    radar: {
                        indicator: [],
                        splitLine: {
                            lineStyle: {
                                color: '#80C3FE',
                            }
                        },
                        splitArea: {
                            areaStyle: {
                                color: ['#F0F8FF']
                            }
                        }
                    },
                    tooltip: {},
                    series: [{
                        name: 'Budget vs spending',
                        type: 'radar',
                        symbol: 'circle',
                        symbolSize: 8,
                        data: [{
                                value: [0, 0, 0, 0, 0, 0],
                                name: '学生姓名',
                                areaStyle: {
                                    color: '#F3DCD7'
                                },
                                itemStyle: {
                                    color: '#FE8152'
                                }
                            },
                            {
                                value: [0, 0, 0, 0, 0, 0],
                                name: '年级平均',
                                areaStyle: {
                                    color: '#B9E6FC'
                                },
                                itemStyle: {
                                    color: '#03ACF3'
                                }
                            }
                        ]
                    }]
                }
            }
        },
        methods: {
            toggle(type) {
                this.type = type;
                this.report(); //获取报告数据
                this.power = [];
                this.getPower(); //获取个人能力对比
            },
            initLineCharts() {
                var chartDom = document.getElementById('lineCharts');
                var myChart = echarts.init(chartDom);
                myChart.setOption(this.option)
            },
            initRadarCharts() {
                var chartDom = document.getElementById('radarChart');
                var myChart = echarts.init(chartDom);
                myChart.setOption(this.radarOption)
            },
            report() {
                let data;
                if (this.$store.state.user.role == 'teacher') {
                    data = {
                        stageId: this.$route.params.id
                    }
                } else {
                    data = {
                        stageId: this.$route.query.stageId
                    }
                }

                if (this.type == 'exercise') {
                    this.$Api.Activity.getReport(data)
                        .then(res => {
                            this.reportInfo = res.data;
                        })
                        .catch(err => {
                            console.log('获取失败', err)
                        })
                } else {
                    this.$Api.Activity.getReport_official(data)
                        .then(res => {
                            // console.log('获取成功', res)
                            this.reportInfo = res.data
                        })
                        .catch(err => {
                            console.log('获取失败', err)
                        })
                }

            },
            // 时间转换
            timeFormat(time) {
                // var d = parseInt(time / (1000 * 60 * 60 * 24));
                // var h = parseInt((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
                var m = parseInt((time % (1000 * 60 * 60)) / (1000 * 60));
                var s = parseInt((time % (1000 * 60)) / 1000);
                // if (h < 10) {
                //     h = '0' + h
                // }
                // this.countdown = d ? d + ':' : '' + h + ':' + (m < 10 ? '0' + m : m) + ':' + (s < 10 ? '0' + s : s)
                return (m < 10 ? '0' + m : m) + ':' + (s < 10 ? '0' + s : s)
            },
            // 获取个人能力、雷达图
            getPower() {
                let data;
                if (this.$store.state.user.role == 'teacher') {
                    data = {
                        stageId: this.$route.params.id,
                        type: this.type == 'exercise' ? 0 : 1
                    }
                } else {
                    data = {
                        stageId: this.$route.query.stageId,
                        type: this.type == 'exercise' ? 0 : 1
                    }
                }

                this.$Api.Activity.getPower(data)
                    .then(res => {
                        // console.log('获取能力成功', res)
                        let avg = JSON.parse(res.data.avg)
                        let personal = JSON.parse(res.data.personal)
                        console.log('年级水平', avg);
                        console.log('个人水平', personal);
                        let indicator = [];
                        let perarr = [];
                        let genarr = [];
                        for (var i = 0; i < personal.length; i++) {
                            for (var j = 0; j < avg.length; j++) {
                                if (personal[i].abilityName == avg[j].abilityName) {
                                    let obj = {
                                        name: personal[i].abilityName
                                    };
                                    personal[i].rate > avg[j].rate ? (personal[i].level = '高于', obj.max = personal[
                                        i].rate) : personal[i].rate == avg[j].rate ? (personal[i].level = '持平',
                                        obj.max = personal[i].rate) : (
                                        personal[i].level = '低于', obj.max = avg[j].rate)
                                    obj.max = 100;
                                    // console.log('obj==',obj);
                                    indicator.push(obj);
                                    perarr.push((personal[i].rate / personal[i].count).toFixed(2));
                                    genarr.push((avg[j].rate / avg[j].count).toFixed(2));
                                    break
                                }
                            }
                        }
                        console.log('indicator', indicator);
                        // console.log('perarr', perarr);
                        // console.log('genarr', genarr);
                        this.power = personal;
                        this.$set(this.radarOption.radar, 'indicator', indicator);
                        this.$set(this.radarOption.series[0].data[0], 'value', perarr);
                        this.$set(this.radarOption.series[0].data[1], 'value', genarr);
                        this.initRadarCharts();
                    })
                    .catch(err => {
                        // console.log('获取能力失败', err)
                        this.initRadarCharts();

                    })

            },
            // 获取模拟折线图
            lineChartsData() {
                let data;
                if (this.$store.state.user.role == 'teacher') {
                    data = {
                        stageId: this.$route.params.id,
                        type: this.type == 'exercise' ? 0 : 1
                    }
                } else {
                    data = {
                        stageId: this.$route.query.stageId,
                        type: this.type == 'exercise' ? 0 : 1
                    }
                }

                this.getStageTime()
                    .then(res => {
                        // console.log('折线图x轴坐标', this.option.xAxis.data)
                        this.$Api.Activity.getLineCharts(data)
                            .then(res => {
                                console.log('获取折线图成功', res)
                                // 活动平均
                                let activeArr = [];
                                let classArr = [];
                                let personArr = [];
                                try {
                                    for (var i = 0; i < this.option.xAxis.data.length; i++) {
                                        for (var j = 0; j < res.data.avgActivity.length; j++) {
                                            if (this.option.xAxis.data[i] == res.data.avgActivity[j].month +
                                                '月' +
                                                res.data.avgActivity[j].day + '日') {
                                                activeArr.push(Number((res.data.avgActivity[j].rateAvg / res
                                                    .data
                                                    .avgActivity[j].participateCount).toFixed(2)))
                                            } else {
                                                activeArr.push(0)
                                            }
                                        }
                                    };
                                } catch (err) {
                                    console.log('平均成绩报错', err)
                                }
                                try {
                                    for (var i = 0; i < this.option.xAxis.data.length; i++) {
                                        for (var j = 0; j < res.data.avgClass.length; j++) {
                                            if (this.option.xAxis.data[i] == res.data.avgClass[j].month + '月' +
                                                res.data.avgClass[j].day + '日') {
                                                classArr.push(Number((res.data.avgClass[j].rateAvg / res.data
                                                    .avgClass[j].participateCount).toFixed(2)))
                                            } else {
                                                classArr.push(0)
                                            }
                                        }
                                    };
                                } catch (err) {
                                    console.log('班级平均报错', err)
                                }
                                try {
                                    for (var i = 0; i < this.option.xAxis.data.length; i++) {
                                        try {
                                            for (var j = 0; j < res.data.personal.length; j++) {
                                                if (this.option.xAxis.data[i] == res.data.personal[j].month +
                                                    '月' +
                                                    res.data.personal[j].day + '日') {
                                                    personArr.push(Number((res.data.personal[j].rateAvg / res
                                                        .data
                                                        .personal[j].participateCount).toFixed(2)))
                                                } else {
                                                    personArr.push(0)
                                                }
                                            }
                                        } catch (err) {
                                            personArr.push(0)
                                        }
                                    }
                                } catch (err) {
                                    console.log('个人平均报错', err)
                                }
                                console.log('activeArr', activeArr);
                                console.log('classArr', classArr);
                                console.log('personArr', personArr);

                                let obj = {
                                    data: activeArr,
                                    type: 'line'
                                }
                                let obj2 = {
                                    data: classArr,
                                    type: 'line'
                                }
                                let obj3 = {
                                    data: personArr,
                                    type: 'line'
                                }
                                let arr = [obj3, obj, obj2]
                                // console.log('arr', arr)
                                this.$set(this.option, 'series', arr);
                                this.initLineCharts();

                            })
                            .catch(err => {
                                console.log('获取折线图失败', err);
                                this.initLineCharts();
                            })
                    })
                    .catch(err => {
                        console.log(err)
                    })

            },
            // 获取折线图x轴坐标
            getStageTime() {
                let params
                if (this.$store.state.user.role == 'teacher') {
                    params = {
                        stageId: this.$route.params.id
                    }
                } else {
                    params = {
                        stageId: this.$route.query.stageId
                    }
                }
                return new Promise((resolve, reject) => {
                    this.$Api.Activity.getStageTime(params)
                        .then(res => {
                            // console.log('获取开始时间成功', res);
                            if (!res.data || JSON.stringify(res.data) == '[]') return;
                            // 格式化开始时间和结束时间 
                            // 不格式可能由于开始时间到结束时间不到24小时而减少一天 所以必须格式化
                            let startTime = this.dayjs(res.data[0]).format('YYYY-MM-DD');
                            let endTime = this.dayjs(res.data[1]).format('YYYY-MM-DD');
                            // 计算开始时间到结束时间的差值
                            let dayDiff = this.dayjs(endTime).diff(this.dayjs(startTime), 'day')
                            // 循环并得出折线图x轴的离散数组  
                            // diff必须+1 因为要取最后一天的数据
                            // console.log('dayDiff', dayDiff)
                            for (let i = 0; i < dayDiff + 1; i++) {
                                this.option.xAxis.data.push(this.dayjs(startTime).add(i, 'day').format(
                                    'MM月DD日'));
                            }
                            resolve('获取折线图x轴坐标成功')
                        })
                        .catch(err => {
                            console.log('获取开始时间失败', err)
                            reject('获取折线图x轴坐标失败')
                        })

                })


            }

        },
        mounted() {
            this.getPower(); //获取个人能力对比
            this.report(); //获取概览、模拟学情分析数据
            this.lineChartsData(); //获取折线图
        },
    };
</script>

<style lang='less' scoped>
    .report-content {
        width: 1200px;
        background: #fff;
        border-radius: 10px;
        padding-bottom: 55px;
        margin-bottom: 144px;

        .ctop {
            background: #F2F7FF;
            height: 70px;

            .exercise {
                width: 178px;
                height: 100%;
                font-size: 18px;
                color: #333;
                text-align: center;
                line-height: 70px;
                box-sizing: border-box;
            }

            .game {
                .exercise()
            }

            .active {
                background: #fff;
                border-top: 2px solid #508EF9;
            }
        }

        .gailan {
            box-sizing: border-box;
            margin-top: 44px;
            padding: 0 34px;
            margin-bottom: 60px;

            .gltitle {
                height: 44px;
                background-image: url("~@/assets/images/active/gailan.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                font-size: 18px;
                color: #fff;
            }

            .moniinfo {
                margin-top: 50px;
                justify-content: center;

                .score {
                    width: 384px;
                    height: 238px;
                    border-radius: 10px;
                    overflow: hidden;
                    background: #F2F7FF;

                    .score_top {
                        height: 70px;
                        background-color: #508EF9;
                        font-size: 20px;
                        color: #fff;

                        img {
                            width: 23px;
                            height: 23px;
                            margin-right: 16px;
                        }
                    }

                    .score_bottom {
                        font-size: 72px;
                        color: #508EF9;
                        text-align: center;
                        line-height: 168px;

                        span {
                            font-size: 30px;
                        }
                    }

                }

                .line {
                    width: 1px;
                    height: 237px;
                    background: #DEDEDE;
                    margin: 0 90px;
                }

                .count {
                    width: 384px;
                    height: 238px;
                    border-radius: 10px;
                    overflow: hidden;
                    background: #FFF7EC;

                    .count_top {
                        height: 70px;
                        background-color: #FDB139;
                        font-size: 20px;
                        color: #fff;

                        img {
                            width: 23px;
                            height: 23px;
                            margin-right: 16px;
                        }
                    }

                    .count_bottom {
                        font-size: 72px;
                        color: #FDB139;
                        text-align: center;
                        line-height: 168px;

                        span {
                            font-size: 30px;
                        }
                    }
                }
            }
        }

        .study {
            box-sizing: border-box;
            padding: 0 34px;

            .stutitle {
                height: 74px;
                background-image: url("~@/assets/images/active/shuju.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;
                display: flex;
                justify-content: space-between;

                span {
                    display: inline-block;

                    &:first-child {
                        margin-left: 147px;
                        margin-top: 17px;
                        font-size: 18px;
                        color: #508EF9;
                    }

                    &:last-child {
                        margin-top: 19px;
                        font-size: 14px;
                        color: #999999;
                        margin-right: 177px;
                    }
                }
            }

            .stuinfo {
                margin: 40px auto;
                width: 946px;
                height: 154px;
                background: #F7F7F7;
                border: 1px solid #CCCCCC;
                display: flex;
                align-items: center;
                justify-content: space-evenly;

                .infoitem {
                    .infotitle {
                        font-size: 17px;
                        color: #333;
                    }

                    .infocount {
                        color: #508EF9;
                        font-size: 55px;

                        span {
                            font-size: 16px;
                        }
                    }
                }

                .line {
                    width: 1px;
                    height: 113px;
                    background: #ccc;
                }
            }

            .chartwrap {
                display: flex;
                align-items: center;
                justify-content: space-evenly;

                .myCharts {
                    width: 734px;
                    height: 404px;
                }

                .legend {
                    display: flex;
                    flex-direction: column;

                    .legenditem {
                        display: flex;
                        align-items: center;
                        margin-bottom: 40px;

                        span {
                            color: #999;
                            font-size: 16px;
                            margin-left: 15px;
                        }

                        .item1 {
                            width: 14px;
                            height: 14px;
                            background: #EB4133;
                        }

                        .item2 {
                            .item1();
                            background: #F8BD09;
                        }

                        .item3 {
                            .item1();
                            background: #7832DA;
                        }
                    }
                }
            }


        }

        .power {
            box-sizing: border-box;
            margin-top: 20px;
            padding: 0 34px;

            .powertitle {
                height: 74px;
                background-image: url("~@/assets/images/active/readpower.png");
                background-size: 100% 100%;
                background-repeat: no-repeat;

                span {
                    margin-left: 148px;
                    margin-top: 18px;
                    font-size: 18px;
                    color: #508EF9;
                }
            }

            .left {
                width: 600px;
                height: 400px;
            }

            .line {
                width: 1px;
                height: 380px;
                background: #ccc;
            }

            .right {
                span {
                    color: #333;
                    font-size: 16px;
                    margin-left: 50px;
                }

                .info {
                    width: 500px;
                    height: 360px;
                    background: #F0F8FF;
                    border-radius: 10px;
                    margin-left: 50px;
                    margin-top: 10px;
                    box-sizing: border-box;
                    padding: 20px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-evenly;

                    span {
                        font-size: 16px;
                        margin-left: 0;

                        span:nth-child(1) {
                            color: #FE8152;
                        }

                        span:nth-child(2) {
                            color: #03ACF3;
                        }
                    }

                    div {
                        font-size: 15px;
                        height: 40px;
                        line-height: 40px;
                        color: #FE8152;
                        background: #fff;
                        border: 1px dashed #FE8152;
                        border-radius: 50px;
                        text-align: center;
                    }
                }
            }
        }
    }
</style>